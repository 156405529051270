var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admin-interfaces-settings admin-police-settings v-step-police-settings-section"},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v(_vm._s(_vm.$t('admin.police-settings.title')))])]),(_vm.communitySetting)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"disable-panic-settings"},[_c('toggle-button',{attrs:{"id":"admin-disable-panic-settings","sync":true,"labels":{
          checked: _vm.$t('panic-buttons-dispatch_component.on'),
          unchecked: _vm.$t('panic-buttons-dispatch_component.off')
        }},on:{"change":_vm.updateOfficerCanDisablePanic},model:{value:(_vm.communitySetting.officerCanDisablePanic),callback:function ($$v) {_vm.$set(_vm.communitySetting, "officerCanDisablePanic", $$v)},expression:"communitySetting.officerCanDisablePanic"}}),_c('p',[_vm._v(_vm._s(_vm.$t('admin.police-settings.allow-officer-to-disable-panic-button')))])],1),_c('div',{staticClass:"disable-panic-settings"},[_c('toggle-button',{attrs:{"id":"only-admins-can-change-max-seats","sync":true,"labels":{
          checked: _vm.$t('panic-buttons-dispatch_component.on'),
          unchecked: _vm.$t('panic-buttons-dispatch_component.off')
        }},on:{"change":_vm.updateOnlyAdminsCanChangeMaxSeats},model:{value:(_vm.communitySetting.onlyAdminsCanChangeMaxSeats),callback:function ($$v) {_vm.$set(_vm.communitySetting, "onlyAdminsCanChangeMaxSeats", $$v)},expression:"communitySetting.onlyAdminsCanChangeMaxSeats"}}),_c('p',[_vm._v(_vm._s(_vm.$t('admin.police-settings.allow-only-admin-to-change-max-seats')))])],1),_c('div',{staticClass:"disable-panic-settings"},[_c('toggle-button',{attrs:{"id":"only-admins-can-create-new-units","sync":true,"labels":{
          checked: _vm.$t('panic-buttons-dispatch_component.on'),
          unchecked: _vm.$t('panic-buttons-dispatch_component.off')
        }},on:{"change":_vm.updateOnlyAdminsCanCreateNewUnits},model:{value:(_vm.communitySetting.onlyAdminsCanCreateNewUnits),callback:function ($$v) {_vm.$set(_vm.communitySetting, "onlyAdminsCanCreateNewUnits", $$v)},expression:"communitySetting.onlyAdminsCanCreateNewUnits"}}),_c('p',[_vm._v(_vm._s(_vm.$t('admin.police-settings.allow-only-admin-to-create-new-units')))])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }