<template>
  <div class="admin-interfaces-settings admin-police-settings v-step-police-settings-section">
    <div class="title">
      <h1>{{ $t('admin.police-settings.title') }}</h1>
    </div>
    <div class="content" v-if="communitySetting">
      <div class="disable-panic-settings">
        <toggle-button
          id="admin-disable-panic-settings"
          :sync="true"
          v-model="communitySetting.officerCanDisablePanic"
          :labels="{
            checked: $t('panic-buttons-dispatch_component.on'),
            unchecked: $t('panic-buttons-dispatch_component.off')
          }"
          @change="updateOfficerCanDisablePanic"
        ></toggle-button>
        <p>{{ $t('admin.police-settings.allow-officer-to-disable-panic-button') }}</p>
      </div>
      <div class="disable-panic-settings">
        <toggle-button
          id="only-admins-can-change-max-seats"
          :sync="true"
          v-model="communitySetting.onlyAdminsCanChangeMaxSeats"
          :labels="{
            checked: $t('panic-buttons-dispatch_component.on'),
            unchecked: $t('panic-buttons-dispatch_component.off')
          }"
          @change="updateOnlyAdminsCanChangeMaxSeats"
        ></toggle-button>
        <p>{{ $t('admin.police-settings.allow-only-admin-to-change-max-seats') }}</p>
      </div>
      <div class="disable-panic-settings">
        <toggle-button
          id="only-admins-can-create-new-units"
          :sync="true"
          v-model="communitySetting.onlyAdminsCanCreateNewUnits"
          :labels="{
            checked: $t('panic-buttons-dispatch_component.on'),
            unchecked: $t('panic-buttons-dispatch_component.off')
          }"
          @change="updateOnlyAdminsCanCreateNewUnits"
        ></toggle-button>
        <p>{{ $t('admin.police-settings.allow-only-admin-to-create-new-units') }}</p>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/dashboards/user-interfaces.less"></style>

<script>
export default {
  name: 'civilian-settings',
  data() {
    return {
      communitySetting: {
        officerCanDisablePanic: false
      }
    }
  },
  apollo: {
    communitySetting: {
      query: require('@/graphql/admin/fetchCommunitySetting.gql'),
      variables() {
        return {
          communityId: this.$store.state.selection.community.community.id
        }
      },
      update(data) {
        return data.community.settings
      },
      subscribeToMore: {
        document: require('@/graphql/admin/subscribeToCommunitySetting.gql'),
        variables() {
          return {
            communityId: this.$store.state.selection.community.community.id
          }
        },
        updateQuery(previousResult, { subscriptionData }) {
          const communitySetting = subscriptionData.data.communitySetting

          return {
            community: {
              __typename: 'Community',
              settings: communitySetting.node
            }
          }
        }
      }
    }
  },
  methods: {
    updateOfficerCanDisablePanic() {
      this.$store
        .dispatch('admin/updateCommunitySetting', {
          communitySettingId: this.communitySetting.id,
          data: { officerCanDisablePanic: this.communitySetting.officerCanDisablePanic }
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    updateOnlyAdminsCanChangeMaxSeats() {
      this.$store
        .dispatch('admin/updateCommunitySetting', {
          communitySettingId: this.communitySetting.id,
          data: { onlyAdminsCanChangeMaxSeats: this.communitySetting.onlyAdminsCanChangeMaxSeats }
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    updateOnlyAdminsCanCreateNewUnits() {
      this.$store
        .dispatch('admin/updateCommunitySetting', {
          communitySettingId: this.communitySetting.id,
          data: { onlyAdminsCanCreateNewUnits: this.communitySetting.onlyAdminsCanCreateNewUnits }
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    }
  }
}
</script>
